import React, { createContext, useContext, useState } from 'react';

const InfoContext = createContext({ page: 0, size: 10, searchWrd: '', exposureYn: '' });

const InfoProvider = ({ children }) => {
    const [options, setOptions] = useState({ page: 0, size: 10, searchWrd: '', exposureYn: '' });

    const value = {
        options,
        setOptions
    };

    return <InfoContext.Provider value={value}>{children}</InfoContext.Provider>;
};

const useInfoContext = () => {
    return useContext(InfoContext);
};

export { InfoProvider, useInfoContext };
