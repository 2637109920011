export { default as FaqBoardConfig } from './faq';
export { default as QnaConfig } from './qna';
export { default as ContactConfig } from './contact';
export { default as ReviewConfig } from './review';
export { default as NoticeBoardConfig } from './notice';
export { default as NewsBoardConfig } from './news';
export { default as PortfolioBoardConfig } from './portfolio';
export { default as CertBoardConfig } from './cert';
export { default as YoutubeBoardConfig } from './youtube';
export { default as StaffBoardConfig } from './staff';
export { default as InquiryBoardConfig } from './inquiry';
export { default as GalleryBoardConfig } from './gallery';
export { default as InfoBoardConfig } from './info';
