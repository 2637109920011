import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const CommunityBoardApi = createApi({
    reducerPath: 'CommunityBoardApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['NoticeBoard'],
    endpoints: (build) => ({
        // NOTICE BOARD
        getNoticeBoard: build.query({
            query: (params) => `${URL.NOTICE_BOARD_LIST}?${params}`,
            providesTags: ['NOTICELIST']
        }),
        getNoticeBoardDetails: build.query({
            query: (params) => `${URL.NOTICE_BOARD_DETAIL}/${params}`,
            providesTags: ['NOTICELIST']
        }),
        createNoticeBoard: build.mutation({
            query: (body) => ({
                url: `${URL.NOTICE_BOARD_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['NOTICELIST']
        }),
        updateNoticeBoard: build.mutation({
            query: (body) => ({
                url: `${URL.NOTICE_BOARD_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['NOTICELIST']
        }),
        deleteNoticeBoard: build.mutation({
            query: (body) => ({
                url: `${URL.NOTICE_BOARD_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['NOTICELIST']
        }),
        deleteNoticeBoards: build.mutation({
            query: (body) => ({
                url: `${URL.NOTICE_BOARD_DELETE}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['NOTICELIST']
        }),
        // FREE BOARD
        getFreeBoard: build.query({
            query: (params) => `${URL.FREE_BOARD_LIST}?${params}`,
            providesTags: ['FREELIST']
        }),
        getFreeBoardDetails: build.query({
            query: (params) => `${URL.FREE_BOARD_DETAIL}/${params}`,
            providesTags: ['FREELIST']
        }),
        createFreeBoard: build.mutation({
            query: (body) => ({
                url: `${URL.FREE_BOARD_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['FREELIST']
        }),
        updateFreeBoard: build.mutation({
            query: (body) => ({
                url: `${URL.FREE_BOARD_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['FREELIST']
        }),
        deleteFreeBoard: build.mutation({
            query: (body) => ({
                url: `${URL.FREE_BOARD_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['FREELIST']
        }),
        deleteFreeBoards: build.mutation({
            query: (body) => ({
                url: `${URL.FREE_BOARD_DELETE}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['FREELIST']
        }),
        // COMMENT
        createComment: build.mutation({
            query: (body) => ({
                url: `${URL.COMMUNITY_COMMENT_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['NOTICELIST', 'FREELIST']
        }),
        updateComment: build.mutation({
            query: (body) => ({
                url: `${URL.COMMUNITY_COMMENT_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['NOTICELIST', 'FREELIST']
        }),
        deleteComment: build.mutation({
            query: (body) => ({
                url: `${URL.COMMUNITY_COMMENT_DELETE}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['NOTICELIST', 'FREELIST']
        }),
        // news
        getNewsBoard: build.query({
            query: (params) => `${URL.NEWS_BOARD_LIST}?${params}`,
            providesTags: ['NEWSLIST']
        }),
        getNewsBoardDetails: build.query({
            query: (params) => `${URL.NEWS_BOARD_DETAIL}/${params}`,
            providesTags: ['NEWSLIST']
        }),
        createNewsBoard: build.mutation({
            query: (body) => ({
                url: `${URL.NEWS_BOARD_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['NEWSLIST']
        }),
        updateNewsBoard: build.mutation({
            query: (body) => ({
                url: `${URL.NEWS_BOARD_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['NEWSLIST']
        }),
        deleteNewsBoard: build.mutation({
            query: (body) => ({
                url: `${URL.NEWS_BOARD_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['NEWSLIST']
        }),
        deleteNewsBoards: build.mutation({
            query: (body) => ({
                url: `${URL.NEWS_BOARD_DELETE}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['NEWSLIST']
        }),
        // INFO BOARD
        getInfoBoard: build.query({
            query: (params) => `${URL.INFO_BOARD_LIST}?${params}`,
            providesTags: ['INFO_LIST']
        }),
        getInfoBoardDetails: build.query({
            query: (params) => `${URL.INFO_BOARD_DETAIL}/${params}`,
            providesTags: ['INFO_LIST']
        }),
        createInfoBoard: build.mutation({
            query: (body) => ({
                url: `${URL.INFO_BOARD_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['INFO_LIST']
        }),
        updateInfoBoard: build.mutation({
            query: (body) => ({
                url: `${URL.INFO_BOARD_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['INFO_LIST']
        }),
        deleteInfoBoard: build.mutation({
            query: (body) => ({
                url: `${URL.INFO_BOARD_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['INFO_LIST']
        }),
        deleteInfoBoards: build.mutation({
            query: (body) => ({
                url: `${URL.NOTICE_BOARD_DELETE}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['INFO_LIST']
        }),
    })
});

export const {
    // NOTICE BOARD
    useGetNoticeBoardQuery,
    useGetNoticeBoardDetailsQuery,
    useCreateNoticeBoardMutation,
    useUpdateNoticeBoardMutation,
    useDeleteNoticeBoardMutation,
    useDeleteNoticeBoardsMutation,
    // FREE BOARD
    useGetFreeBoardQuery,
    useGetFreeBoardDetailsQuery,
    useCreateFreeBoardMutation,
    useUpdateFreeBoardMutation,
    useDeleteFreeBoardMutation,
    useDeleteFreeBoardsMutation,
    // COMMENT
    useCreateCommentMutation,
    useUpdateCommentMutation,
    useDeleteCommentMutation,

    // NEWS
    useGetNewsBoardQuery,
    useGetNewsBoardDetailsQuery,
    useCreateNewsBoardMutation,
    useUpdateNewsBoardMutation,
    useDeleteNewsBoardMutation,
    useDeleteNewsBoardsMutation,

    // INFO
    useGetInfoBoardQuery,
    useGetInfoBoardDetailsQuery,
    useCreateInfoBoardMutation,
    useUpdateInfoBoardMutation,
    useDeleteInfoBoardMutation
} = CommunityBoardApi;
